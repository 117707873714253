import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Select, Col } from 'antd';
import LocationSearchSelect from 'components/Select/LocationSearchSelect';
import {
  MUTATION_BULK_UPDATE_CONTAINER,
  QUERY_CONTAINER_STATUSES,
  QUERY_GET_CONTAINER,
  QUERY_LIST_CONTAINERS,
} from '../queries';

const { Option } = Select;

const AssignLocationAndStatusModal = ({ visible, records, onClose }: any) => {
  const [loading, setLoading] = useState(false);
  const [updateBulkContainer] = useMutation(MUTATION_BULK_UPDATE_CONTAINER);

  const handleAssignLocationAndStatus = useCallback(
    async (values: any) => {
      setLoading(true);

      const primaryKeys = records.map((record: any) => ({
        container_id: record.container_id,
      }));
      await updateBulkContainer({
        variables: {
          primaryKeys,
          input: values,
        },
        refetchQueries: [
          {
            query: QUERY_LIST_CONTAINERS,
          },
          ...primaryKeys.map((primaryKeys: { container_id: number }) => ({
            query: QUERY_GET_CONTAINER,
            variables: primaryKeys,
          })),
        ],
      });

      setLoading(false);
      onClose(true);
    },
    [records, onClose, updateBulkContainer],
  );

  const { data: statuses, error } = useQuery(QUERY_CONTAINER_STATUSES);

  return (
    <Modal
      title="Assign location and status to selected containers"
      open={visible}
      onOk={handleAssignLocationAndStatus}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <Form
        name="cup_location_and_status"
        onFinish={handleAssignLocationAndStatus}
      >
        <div>
          Selected:{' '}
          {records?.length > 25 ? (
            <>
              <b>{records.length}</b> containers.
            </>
          ) : null}
        </div>

        <Col style={{ marginBottom: 20 }}>
          {records?.length <= 25
            ? records.map((c: any, index: number) => (
                <React.Fragment key={c.unique_name}>
                  <b>{c.unique_name}</b>
                  {index !== records.length - 1 ? ', ' : null}
                </React.Fragment>
              ))
            : null}
        </Col>

        <Form.Item
          label="Status"
          name="current_status_id"
          rules={[{ required: true, message: 'Please select status!' }]}
        >
          <Select placeholder="Pick Status">
            {statuses && !error
              ? statuses.listContainerStatus.map((status: any) => (
                  <Option key={status.status_id} value={status.status_id}>
                    {status.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item
          label="Location"
          name="last_location_id"
          rules={[{ required: true, message: 'Please select location!' }]}
        >
          <LocationSearchSelect placeholder="Search for Location" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignLocationAndStatusModal;
