import React, { useCallback, useMemo } from 'react';
import { Button, Typography, Row, Col, Space, Alert } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  MUTATION_REMOVE_USER_FROM_DB,
  MUTATION_REMOVE_USER_FROM_FIREBASE,
  MUTATION_REMOVE_USER_FROM_MARKETING,
  MUTATION_REMOVE_USER_FROM_STRIPE,
} from '../../../../queries/UserQueries';

const { Title, Text } = Typography;

const DangerZone = ({ user }: any) => {
  const [removeFromStripe, { data: stripeData, loading: stripeLoading }] =
    useMutation(MUTATION_REMOVE_USER_FROM_STRIPE);
  const [
    removeFromMarketing,
    { data: marketingData, loading: marketingLoading },
  ] = useMutation(MUTATION_REMOVE_USER_FROM_MARKETING);
  const [removeFromFirebase, { data: firebaseData, loading: firebaseLoading }] =
    useMutation(MUTATION_REMOVE_USER_FROM_FIREBASE);
  const [removeFromDB, { data: dbData, loading: dbLoading }] = useMutation(
    MUTATION_REMOVE_USER_FROM_DB,
  );

  const stripeUserMembership = useMemo(
    () =>
      user?.user_membership.find(
        (m: Record<string, any>) => m.paymentProcessor?.name === 'Stripe',
      ),
    [user],
  );

  const handleRemoveFromStripe = useCallback(async () => {
    try {
      await removeFromStripe({
        variables: {
          stripe_customer_id: user.stripe_customer_id,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [user]);

  const handleRemoveFromMarketing = useCallback(async () => {
    try {
      await removeFromMarketing({
        variables: { email: user.email },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [user]);

  const handleRemoveFromFirebase = useCallback(async () => {
    try {
      await removeFromFirebase({
        variables: { firebase_user_id: user.firebase_user_id },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [user]);

  const handleRemoveFromDb = useCallback(async () => {
    try {
      await removeFromDB({
        variables: { user_id: user.user_id },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [user]);

  return (
    <Row
      style={{
        borderColor: 'red',
        borderRadius: '6px',
        borderWidth: 5,
        borderStyle: 'solid',
        padding: 20,
      }}
    >
      <Col span={24}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Title level={3} style={{ color: 'red' }}>
            Danger zone
          </Title>
          <Text type={'danger'} style={{ fontSize: 11 }}>
            Make sure you don’t remove this user from the DB until they are
            removed from everywhere else.
          </Text>
          {!!stripeUserMembership && (
            <Row align={'middle'}>
              <Button
                disabled={!!stripeData}
                onClick={handleRemoveFromStripe}
                loading={stripeLoading}
              >
                Remove From Stripe
              </Button>
              {stripeData && <Text style={{ marginLeft: 10 }}>Done!</Text>}
            </Row>
          )}
          <Row align={'middle'}>
            <Button
              loading={marketingLoading}
              disabled={!!marketingData}
              onClick={handleRemoveFromMarketing}
            >
              Remove From Marketing
            </Button>
            {marketingData && <Text style={{ marginLeft: 10 }}>Done!</Text>}
          </Row>
          <Row align={'middle'}>
            <Button
              loading={firebaseLoading}
              disabled={!!firebaseData}
              onClick={handleRemoveFromFirebase}
            >
              Remove from Firebase
            </Button>
            {firebaseData && <Text style={{ marginLeft: 10 }}>Done!</Text>}
          </Row>
          <Row align={'middle'}>
            <Button
              loading={dbLoading}
              disabled={!!dbData}
              onClick={handleRemoveFromDb}
            >
              Remove from DB
            </Button>
            {dbData && <Text style={{ marginLeft: 10 }}>Done!</Text>}
          </Row>
          <Row align={'middle'}>
            <ExclamationCircleFilled
              style={{ marginRight: 10, fontSize: 20, color: 'red' }}
            />
            <Text>Don’t forget to manually remove from Zendesk.</Text>
          </Row>
          {dbData && (
            <Alert message="User's data successfully removed!" type="success" />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default DangerZone;
