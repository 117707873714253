import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const AssigmentSvg = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 6L8 7.33333V8.66667H4.66667V12.6667L4 13.3333L3.33333 12.6667V8.66667H0V7.33333L1.33333 6V1.33333H0.666667V0H7.33333V1.33333H6.66667V6ZM1.9 7.33333H6.1L5.33333 6.56667V1.33333H2.66667V6.56667L1.9 7.33333Z"
      fill="#605E55"
    />
  </svg>
);

const AssigmentIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AssigmentSvg} {...props} />
);

export default AssigmentIcon;
