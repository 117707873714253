import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const LocationSvg = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99999 7.00001C6.36666 7.00001 6.68055 6.86945 6.94166 6.60834C7.20277 6.34723 7.33332 6.03334 7.33332 5.66668C7.33332 5.30001 7.20277 4.98612 6.94166 4.72501C6.68055 4.4639 6.36666 4.33334 5.99999 4.33334C5.63332 4.33334 5.31943 4.4639 5.05832 4.72501C4.79721 4.98612 4.66666 5.30001 4.66666 5.66668C4.66666 6.03334 4.79721 6.34723 5.05832 6.60834C5.31943 6.86945 5.63332 7.00001 5.99999 7.00001ZM5.99999 11.9C7.35555 10.6556 8.3611 9.52501 9.01666 8.50834C9.67221 7.49168 9.99999 6.5889 9.99999 5.80001C9.99999 4.5889 9.61388 3.59723 8.84166 2.82501C8.06943 2.05279 7.12221 1.66668 5.99999 1.66668C4.87777 1.66668 3.93055 2.05279 3.15832 2.82501C2.3861 3.59723 1.99999 4.5889 1.99999 5.80001C1.99999 6.5889 2.32777 7.49168 2.98332 8.50834C3.63888 9.52501 4.64443 10.6556 5.99999 11.9ZM5.99999 13.6667C4.2111 12.1445 2.87499 10.7306 1.99166 9.42501C1.10832 8.11945 0.666656 6.91112 0.666656 5.80001C0.666656 4.13334 1.20277 2.80557 2.27499 1.81668C3.34721 0.827788 4.58888 0.333344 5.99999 0.333344C7.4111 0.333344 8.65277 0.827788 9.72499 1.81668C10.7972 2.80557 11.3333 4.13334 11.3333 5.80001C11.3333 6.91112 10.8917 8.11945 10.0083 9.42501C9.12499 10.7306 7.78888 12.1445 5.99999 13.6667Z"
      fill="#605E55"
    />
  </svg>
);

const LocationIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LocationSvg} {...props} />
);

export default LocationIcon;
