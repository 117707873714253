import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  DatePicker,
  Button,
  Typography,
  Row,
  Col,
  Descriptions,
  InputNumber,
  notification,
  Input,
  Select,
  Popconfirm,
  message,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  QUERY_ASSIGNMENTS,
  QUERY_ONE_ASSIGNMENT,
  MUTATION_UPDATE_ASSIGNMENT,
  MUTATION_REFUND_ASSIGNMENT,
  MUTATION_REMOVE_ASSIGNMENT,
} from 'queries/AssignmentsQueries';
import { QUERY_ASSIGNMENT_FEE_LEDGER } from 'queries/FeeLedgerQueries';
import { QUERY_LIST_LOCATIONS } from '../Locations/queries';
import { CONTAINER_CHECK_IN } from '../../../queries/ContainersQueries';
import dayjs from 'dayjs';
import routes from '../../../constants/routes';
import { AssignmentStatuses } from '../../../constants/AssignmentStatuses';
import numeral from 'numeral';
import { CURRENCY_FORMAT } from 'constants/numberformats';
import { Link, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import FeeLedgerTable from '../../../components/Tables/FeeLedgerTable';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

import './Assignment.sass';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const { Title } = Typography;
const USEFULL_CUSTOMER_SERVICE_LOCATION_ID = 4;

const AssignmentEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [isRefundVisible, setRefundVisible] = useState(false);
  const [toLocation, setToLocation] = useState<number | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const {
    data: assignmentData,
    error: assignmentError,
    loading: assignmentLoading,
    refetch: refetchAssignment,
  } = useQuery(QUERY_ONE_ASSIGNMENT, {
    variables: {
      assignment_id: params.assignment_id,
    },
    fetchPolicy: 'no-cache',
  });

  const assignment = useMemo(
    () => assignmentData?.getAssignment || {},
    [assignmentData],
  );

  const { data: feeLedgerData, error: feeLedgerError } = useQuery(
    QUERY_ASSIGNMENT_FEE_LEDGER,
    {
      variables: {
        assignment_id: params.assignment_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  const { data: listLocationsData } = useQuery(QUERY_LIST_LOCATIONS, {
    variables: {
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
      order: [
        { field: 'pretty_name', order: 'ASC' },
        { field: 'name', order: 'ASC' },
      ],
      pagination: {
        page: 1,
        size: 100,
      },
    },
  });

  const listLocations = useMemo(
    () => listLocationsData?.listLocation,
    [listLocationsData],
  );

  const [updateAssignment] = useMutation(MUTATION_UPDATE_ASSIGNMENT, {
    refetchQueries: [{ query: QUERY_ASSIGNMENTS }],
  });

  const [removeAssignment] = useMutation(MUTATION_REMOVE_ASSIGNMENT, {
    refetchQueries: [QUERY_ASSIGNMENTS],
  });

  const [refundAssignment] = useMutation<{ refundAssignment: boolean }>(
    MUTATION_REFUND_ASSIGNMENT,
    {
      refetchQueries: [{ query: QUERY_ASSIGNMENTS }],
    },
  );

  const [containerCheckIn, { loading: containerCheckInLoading }] =
    useMutation(CONTAINER_CHECK_IN);

  const disableDueOnField = useMemo(
    () =>
      assignment ? assignment?.status_id !== AssignmentStatuses.Active : true,
    [assignment],
  );

  const handleRefundSubmit = useCallback(
    async (values: any) => {
      setRefunding(true);
      const result = await refundAssignment({
        variables: {
          ...values,
          assignment_id: assignment.assignment_id,
          formattedAmount: numeral(values.amount).format(CURRENCY_FORMAT),
        },
      });
      if (result.data?.refundAssignment) {
        setRefundVisible(false);
        await refetchAssignment();
        notification.success({
          message: 'Refund',
          description: 'Refund request sent',
        });
      } else {
        notification.error({
          message: 'Refund',
          description: 'Something went wrong',
        });
      }
      setRefunding(false);
    },
    [assignment, refundAssignment, refetchAssignment],
  );

  const handleEditSubmit = useCallback(
    async (values: any) => {
      setSaving(true);

      await updateAssignment({
        variables: {
          assignment_id: params.assignment_id,
          input: {
            ...values,
            from_location_id: assignment?.from_location_id,
            container_id: assignment?.container_id,
            user_id: assignment?.user_id,
            status_id: assignment?.status_id,
          },
        },
      });
      setSaving(false);

      setTimeout(() => {
        navigate(routes.ASSIGNMENTS);
      }, 100);
    },
    [navigate, assignment, updateAssignment, params],
  );

  useEffect(() => {
    const [firstLocation] = listLocations?.list || [];
    if (firstLocation) {
      setToLocation(
        process.env.REACT_APP_ENV === 'production'
          ? USEFULL_CUSTOMER_SERVICE_LOCATION_ID
          : firstLocation.location_id,
      );
    }
  }, [listLocations]);

  const handleUpdateStatus = useCallback(
    async (status_id: AssignmentStatuses) => {
      setSaving(true);
      await updateAssignment({
        variables: {
          assignment_id: params.assignment_id,
          input: {
            status_id,
          },
        },
      });
      setSaving(false);

      setTimeout(() => {
        navigate(routes.ASSIGNMENTS);
      }, 100);
    },
    [navigate, params.assignment_id, updateAssignment],
  );

  const handleChangeLocation = useCallback(
    (location: any) => {
      setToLocation(location);
    },
    [setToLocation],
  );

  const handleReturn = useCallback(async () => {
    try {
      await containerCheckIn({
        variables: {
          container_id: assignment?.container?.container_id,
          to_location_id: toLocation,
          returned_by: dbUser?.user_id,
        },
      });
      await refetchAssignment();
    } catch (e) {
      console.error(e);
    }
  }, [toLocation, assignment]);

  if (assignmentLoading) {
    return <Title level={3}>loading</Title>;
  }

  if (assignmentError) {
    return (
      <Title level={3}>
        ERROR: <b>{assignmentError?.message}</b>
      </Title>
    );
  }

  const chargedLateFees = _.sumBy(
    assignment.feeLedgerRecords.filter(
      (r: Record<string, any>) => r.processed_on,
    ),
    (r: Record<string, any>) => r.amount,
  );

  const assignmentHasTransactions = !!assignment.feeLedgerRecords.find(
    (r: Record<string, any>) => r?.fee_transactions?.length ?? 0,
  );

  const userMembership =
    assignment?.user?.user_membership[
      assignment?.user?.user_membership.length - 1
    ];
  const paymentProcessorName = userMembership?.paymentProcessor.name;
  const isRefundAllow =
    !!assignment.user?.stripe_payment_token &&
    paymentProcessorName !== 'Stripe' &&
    !!chargedLateFees &&
    !!(chargedLateFees - assignment.refunded_amount);

  if (!dbUser?.userPermissions.ManageAssignments) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10} className="assignment">
      <Col span={24}>
        <Descriptions
          column={1}
          bordered
          title={
            assignment.user ? (
              dbUser?.userPermissions.ManageUsers ? (
                <Link to={routes.USERS + '/' + assignment.user_id}>
                  {assignment?.user?.full_name}
                </Link>
              ) : (
                assignment?.user?.full_name
              )
            ) : (
              <Title>unknown</Title>
            )
          }
        >
          {assignment?.user?.email && (
            <Descriptions.Item label="Email">
              <Button
                onClick={(e) => {
                  window.location.href = `mailto:${assignment.user.email}`;
                  e.preventDefault();
                }}
              >
                {assignment.user.email}
              </Button>
            </Descriptions.Item>
          )}
          {assignment.returned_by_user?.email && (
            <Descriptions.Item label="Returned by">
              {assignment.returned_by_user.email}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Container">
            {dbUser?.userPermissions.ManageCups ||
            dbUser?.userPermissions.ViewCups ? (
              <Link
                to={
                  routes.CONTAINERS + '/' + assignment?.container?.container_id
                }
              >
                {assignment?.container?.unique_name || 'unknown'}
              </Link>
            ) : (
              assignment?.container?.unique_name || 'unknown'
            )}
          </Descriptions.Item>
          {assignment?.container?.corporateClient && (
            <Descriptions.Item label="Corp client">
              {dbUser?.userPermissions.ManageCorporateClients ? (
                <Link
                  to={
                    routes.CORPORATE_CLIENTS +
                    '/' +
                    assignment?.container?.corporateClient?.corporate_client_id
                  }
                >
                  {assignment?.container?.corporateClient?.name}
                </Link>
              ) : (
                assignment?.container?.corporateClient?.name
              )}
            </Descriptions.Item>
          )}
          {assignment.assigned_on && (
            <Descriptions.Item label="Start date">
              {dayjs(assignment.assigned_on).format('LLL')}
            </Descriptions.Item>
          )}
          {assignment.returned_on && (
            <Descriptions.Item label="End date">
              {dayjs(assignment.returned_on).format('LLL')}
            </Descriptions.Item>
          )}
          {assignment.from_location && (
            <Descriptions.Item label="From Location">
              {assignment.from_location?.pretty_name ??
                assignment.from_location?.name}
            </Descriptions.Item>
          )}
          {assignment.to_location && (
            <Descriptions.Item label="To Location">
              {assignment.to_location?.pretty_name ??
                assignment.to_location?.name}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Charged late fees">
            <Row gutter={10} align="middle">
              <Col>{numeral(chargedLateFees).format(CURRENCY_FORMAT)}</Col>
              {isRefundAllow && (
                <Col>
                  {isRefundVisible ? (
                    <Form
                      layout="inline"
                      name="refund"
                      onFinish={handleRefundSubmit}
                      style={{ marginTop: -10, marginBottom: -10 }}
                    >
                      <Form.Item
                        name="processor"
                        hidden
                        initialValue={paymentProcessorName}
                      >
                        <InputNumber type="hidden" />
                      </Form.Item>
                      <Input.Group compact>
                        <Form.Item
                          name="amount"
                          rules={[
                            {
                              type: 'integer',
                              required: true,
                              min: 1,
                              max: chargedLateFees - assignment.refunded_amount,
                              message: `must be between 1 and ${
                                chargedLateFees - assignment.refunded_amount
                              }`,
                            },
                          ]}
                          style={{ marginBottom: 0 }}
                        >
                          <InputNumber
                            type="number"
                            placeholder="Amount"
                            disabled={refunding}
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={refunding}
                        >
                          Refund
                        </Button>
                      </Input.Group>
                    </Form>
                  ) : (
                    <Button type="link" onClick={() => setRefundVisible(true)}>
                      Refund
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={'Fee ledger'}>
            {feeLedgerData && !feeLedgerError && (
              <FeeLedgerTable
                data={feeLedgerData?.getFeeLedger}
                query={QUERY_ASSIGNMENT_FEE_LEDGER}
              />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Refunded amount">
            {paymentProcessorName === 'Stripe' && !!chargedLateFees ? (
              <Row
                gutter={10}
                align="middle"
                style={{ marginTop: -10, marginBottom: -10 }}
              >
                <Col flex="auto">
                  <Form
                    layout="inline"
                    name="refunded_amount"
                    onFinish={handleEditSubmit}
                    initialValues={{
                      refunded_amount: assignment.refunded_amount,
                    }}
                  >
                    <Form.Item
                      name="refunded_amount"
                      rules={[
                        {
                          type: 'number',
                          required: true,
                          min: 0.01,
                          max: chargedLateFees,
                          message: `must be between 0.01 and ${chargedLateFees}`,
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        addonBefore="$"
                        type="number"
                        placeholder="Amount"
                        disabled={saving}
                      />
                    </Form.Item>
                    <Button loading={saving} type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form>
                </Col>
                <Col>
                  Stripe refunds happen in stripe and should be manually
                  reflected here.
                </Col>
              </Row>
            ) : (
              numeral(assignment.refunded_amount).format(CURRENCY_FORMAT)
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Uncharged Late Fees">
            {numeral(
              _.sumBy(
                assignment.feeLedgerRecords.filter(
                  (r: Record<string, any>) => !r.processed_on,
                ),
                (r: Record<string, any>) => r.amount,
              ),
            ).format(CURRENCY_FORMAT)}
          </Descriptions.Item>
          {assignment?.user?.current_client_level?.membershipLevel?.name && (
            <Descriptions.Item label="User membership level">
              {assignment.user.current_client_level.membershipLevel.name}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Status">
            {assignment?.status?.name?.[0]?.toUpperCase()}
            {assignment?.status?.name?.slice(1)}
            {[AssignmentStatuses.Active, AssignmentStatuses.Paused].includes(
              assignment?.status_id,
            ) && (
              <>
                <Button
                  loading={saving}
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    handleUpdateStatus(
                      assignment.status_id === AssignmentStatuses.Paused
                        ? AssignmentStatuses.Active
                        : AssignmentStatuses.Paused,
                    )
                  }
                >
                  {assignment.status_id === AssignmentStatuses.Paused
                    ? 'Activate'
                    : 'Pause'}
                </Button>
                <div className="assignment-space space-lg" />
                <Select
                  value={toLocation}
                  className="assignment-select"
                  options={listLocations?.list?.map((option: any) => {
                    const name = option?.pretty_name || option?.name;
                    return {
                      label: name,
                      value: option?.location_id,
                    };
                  })}
                  onChange={handleChangeLocation}
                />
                <div className="assignment-space space-xs" />
                <Button
                  loading={containerCheckInLoading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 10 }}
                  onClick={handleReturn}
                >
                  Return
                </Button>
              </>
            )}
            {!chargedLateFees &&
              dbUser?.userPermissions.DangerZone &&
              !assignmentHasTransactions && (
                <>
                  <div className="assignment-space space-lg" />
                  {contextHolder}
                  <Popconfirm
                    title="Are you sure you want to delete this assignment entirely?"
                    onConfirm={async () => {
                      const removeRes = await removeAssignment({
                        variables: {
                          assignment_id: assignment.assignment_id,
                        },
                      });

                      if (
                        removeRes.data.removeAssignmentWithoutFees.error_message
                      ) {
                        messageApi.error(
                          removeRes.data.removeAssignmentWithoutFees
                            .error_message.message,
                        );
                      } else {
                        navigate(routes.ASSIGNMENTS);
                      }
                    }}
                  >
                    <Button danger>Delete</Button>
                  </Popconfirm>
                </>
              )}
          </Descriptions.Item>
          {assignment.note && (
            <Descriptions.Item label="Note">
              {assignment.note}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Payment processor">
            {paymentProcessorName || 'unknown'}
          </Descriptions.Item>
          <Descriptions.Item label="Due On">
            <Form
              layout="inline"
              name="assignment-edit"
              onFinish={handleEditSubmit}
              initialValues={{
                ...assignment,
                due_on: dayjs(assignment?.due_on),
              }}
            >
              <Form.Item
                name="due_on"
                rules={[{ required: true, message: 'Please input Due On!' }]}
              >
                <DatePicker disabled={disableDueOnField} placeholder="Due On" />
              </Form.Item>

              {!disableDueOnField && (
                <Button loading={saving} type="primary" htmlType="submit">
                  Save
                </Button>
              )}
            </Form>
            {disableDueOnField
              ? 'you are not able to edit the due date of a completed assignment'
              : ''}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default AssignmentEdit;
