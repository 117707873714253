import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Checkbox, Row, Col } from 'antd';

const { Title } = Typography;

const ToastMessageForm = ({ handleSubmit, toastMessage }: any) => {
  const [loading, setLoading] = useState(false);
  const mode = toastMessage ? 'update' : 'create';

  const [form] = Form.useForm();

  useEffect(() => {
    toastMessage && form.setFieldsValue(toastMessage);
  }, [form, toastMessage]);

  const submit = useCallback(async () => {
    const values = await form.validateFields();
    setLoading(true);
    await handleSubmit(values);
    setLoading(false);
  }, [form, handleSubmit]);

  return (
    <Row gutter={10} style={{ paddingLeft: 10 }}>
      <Form
        name="toast_message_create"
        form={form}
        layout="vertical"
        initialValues={{
          enabled: true,
        }}
        onFinish={submit}
      >
        <Row>
          <Col span={24}>
            <Title level={2}>
              {mode === 'create'
                ? 'Create Toast Message'
                : `Update Toast Message "${toastMessage.message}"`}
            </Title>
          </Col>

          <Col span={24} style={{ margin: '20px 0' }}>
            <Form.Item
              label="Toast Message"
              name="message"
              rules={[{ required: true, message: 'Please input message!' }]}
            >
              <Input placeholder="Toast Message" />
            </Form.Item>

            <Form.Item label="Toast Image Url" name="image_url">
              <Input placeholder="Image Url" />
            </Form.Item>

            <Form.Item label="Enabled" name="enabled" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {mode === 'create' ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default ToastMessageForm;
