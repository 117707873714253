import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Table,
  Form,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
  Select,
  Space,
  Dropdown,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { EditingField } from 'components/EditingField';

import {
  QUERY_LIST_CONTAINERS,
  MUTATION_CREATE_BULK_CONTAINER,
  MUTATION_SET_QR_BATCH,
  PURE_QUERY_LIST_CONTAINERS,
} from './queries';
import routes from 'constants/routes';
import { CupStatuses } from 'constants/CupStatuses';
import ContainerTypeSelect from '../../../components/Select/ContainerTypeSelect';
import { QUERY_LIST_CORPORATE_CLIENTS_WITH_NAMING_CONV } from '../../../queries/DictionariesQueries';
import { UNIQUE_NAME_GENERATOR_QUERIES } from '../../../queries/NameGeneratorQueries';
import printOptions from '../../../constants/PrintOptions';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const { Title } = Typography;
const { Option } = Select;

let cupGenerateIndex = 0;

const CupBulkCreate = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  const client = useApolloClient();
  const {
    data: corpClientsWithNC,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS_WITH_NAMING_CONV, {
    fetchPolicy: 'no-cache',
    variables: { order: [{ field: 'name', order: 'ASC' }] },
  });
  const [createBulkCup, { data: bulkCupData }] = useMutation<{
    createBulkContainer: Array<{ container_id: number }>;
  }>(MUTATION_CREATE_BULK_CONTAINER, {
    refetchQueries: [{ query: QUERY_LIST_CONTAINERS }],
  });

  const [setQrBatch] = useMutation(MUTATION_SET_QR_BATCH, {
    refetchQueries: [PURE_QUERY_LIST_CONTAINERS],
  });

  const [stage, setStage] = useState('generate');
  const [typeId, setTypeId] = useState<number>();
  const [corporateClientId, setCorporateClientId] = useState<number>();
  const [containers, setContainers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const generateContainer = useCallback(
    (unique_name: string, type_id?: number, corporate_client_id?: number) => {
      return {
        id: ++cupGenerateIndex,
        unique_name,
        type_id: type_id ?? typeId,
        corporate_client_id: corporate_client_id ?? corporateClientId,
        current_status_id: CupStatuses.New,
        status_updated_on: new Date(),
      };
    },
    [typeId, corporateClientId],
  );

  const regenerateName = useCallback(async () => {
    const { data } = await client.query({
      query: UNIQUE_NAME_GENERATOR_QUERIES,
      variables: {
        amount: 5,
        corp_client_id: corporateClientId ? +corporateClientId : null,
      },
      fetchPolicy: 'no-cache',
    });

    return data.getUniqueNames.find(
      (uniqName: string) =>
        containers.findIndex((c) => c.unique_name === uniqName) === -1,
    );
  }, [client, containers, corporateClientId]);

  const columns = useMemo(
    () => [
      {
        title: 'Unique name',
        dataIndex: 'unique_name',
        key: 'unique_name',

        render: (unique_name: string, record: any) => {
          return (
            <EditingField
              key={`key-${record.id}`}
              value={unique_name}
              onChange={({ target: { value } }: any) =>
                setContainers((list: any[]) => {
                  record.unique_name = value;
                  list[list.indexOf(record)] = record;
                  return [...list];
                })
              }
            />
          );
        },
      },
      {
        title: 'Regenerate',
        key: 'regenerate',
        width: '100px',
        render: (_: any, record: any) => (
          <Button
            onClick={async () => {
              const name = await regenerateName();
              setContainers((list: any[]) => {
                list[list.indexOf(record)] = generateContainer(
                  name,
                  typeId,
                  corporateClientId,
                );
                return [...list];
              });
            }}
          >
            <SyncOutlined />
          </Button>
        ),
      },
    ],
    [generateContainer, regenerateName, corporateClientId, typeId],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        setStage('create');
        setTypeId(values.type_id);
        setCorporateClientId(values.corporate_client_id);

        const { data } = await client.query({
          query: UNIQUE_NAME_GENERATOR_QUERIES,
          variables: {
            amount: +values.amount,
            corp_client_id: values.corporate_client_id
              ? +values.corporate_client_id
              : null,
          },
          fetchPolicy: 'no-cache',
        });

        setContainers(
          data.getUniqueNames?.map((unique_name: string) =>
            generateContainer(
              unique_name,
              values.type_id,
              values.corporate_client_id,
            ),
          ) ?? [],
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [client, generateContainer],
  );

  const handleSaveCups = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      setSaving(true);
      await createBulkCup({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        variables: { input: containers.map(({ id, ...c }) => c) },
      });
      setSaving(false);
      setStage('done');
    },
    [containers, createBulkCup],
  );

  const handleBack = useCallback(() => {
    navigate(routes.CONTAINERS);
  }, [navigate]);

  const handlePrint = useCallback(
    async (key: string) => {
      try {
        if (bulkCupData?.createBulkContainer) {
          const api = process.env.REACT_APP_GRAPHQL_SERVER_URI || '';
          const container_ids = bulkCupData.createBulkContainer?.map(
            (c) => c.container_id,
          );
          const qrBatchResult: any = await setQrBatch({
            variables: { container_ids },
          });

          if (qrBatchResult.data.setQrBatch) {
            window.open(
              api.replace('/graphql', '') +
                `/print?type=container&format=${key}&batch_ids=` +
                qrBatchResult.data.setQrBatch.join(','),
              '_blank',
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [bulkCupData, setQrBatch],
  );

  if (corpClientsLoading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageLocations) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10} style={{ paddingLeft: 10 }}>
      <Form name="cups_bulk_add" layout="vertical" onFinish={handleSubmit}>
        <Col span={24}>
          <Title level={2}>Generate Containers</Title>
        </Col>

        {stage === 'generate' && (
          <>
            <Form.Item label="Container Type" name="type_id">
              <ContainerTypeSelect placeholder="Type" />
            </Form.Item>
            <Form.Item
              label="Amount of containers to generate"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount of containers to generate!',
                },
              ]}
            >
              <InputNumber
                max={2000}
                min={1}
                style={{ width: '100%' }}
                type="number"
                placeholder="Amount"
              />
            </Form.Item>
            <Form.Item
              label="Property of Corporate Client"
              name="corporate_client_id"
            >
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder="Select Corporate Client"
              >
                {corpClientsWithNC && !corpClientsError
                  ? corpClientsWithNC.listCorporateClient.map((l: any) => (
                      <Option
                        key={l.corporate_client_id}
                        value={`${l.corporate_client_id}`}
                      >
                        {l.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
            <Col span={24}>
              <span>Name will be generated randomly</span>
            </Col>
            <Button type="primary" htmlType="submit">
              Generate
            </Button>
          </>
        )}

        {stage === 'create' && (
          <>
            <Table
              rowKey="id"
              loading={loading}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ['25', '100', '500', '1000'],
              }}
              dataSource={containers}
              columns={columns}
            />
            <Row gutter={15}>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={saving}
                  onClick={handleSaveCups}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {stage === 'done' && (
          <Col>
            <Title style={{ margin: '20px auto' }} level={3}>
              Done!
            </Title>
            <Space>
              <Button onClick={handleBack}>Back</Button>
              <Dropdown.Button
                menu={{
                  items: printOptions,
                  onClick: ({ key }) => handlePrint(key),
                }}
                trigger={['hover']}
              >
                Print QR Codes
              </Dropdown.Button>
            </Space>
          </Col>
        )}
      </Form>
    </Row>
  );
};

export default CupBulkCreate;
