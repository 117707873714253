import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const PowerSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4571 1.08749C11.4353 1.07648 11.411 1.07126 11.3865 1.07235C11.3621 1.07343 11.3383 1.08078 11.3175 1.09369C11.2967 1.10659 11.2796 1.12463 11.2678 1.14605C11.256 1.16748 11.2498 1.19159 11.25 1.21606V2.36249C11.25 2.46071 11.3018 2.55178 11.3839 2.60535C11.7866 2.85881 12.1592 3.15715 12.4946 3.49463C13.0786 4.08035 13.5375 4.76071 13.8571 5.51785C14.1877 6.30165 14.3572 7.14397 14.3554 7.99463C14.3554 8.85356 14.1875 9.68749 13.8571 10.4714C13.5379 11.2275 13.0752 11.9145 12.4946 12.4946C11.9161 13.0766 11.2294 13.5401 10.4732 13.8589C9.69108 14.1911 8.85715 14.3589 8 14.3589C7.14286 14.3589 6.30893 14.1911 5.52679 13.8589C4.77061 13.5401 4.08396 13.0766 3.50536 12.4946C2.92483 11.9145 2.46214 11.2275 2.14286 10.4714C1.81229 9.68762 1.64286 8.84529 1.64465 7.99463C1.64465 7.13571 1.8125 6.30178 2.14286 5.51785C2.4625 4.76071 2.92143 4.08035 3.50536 3.49463C3.84465 3.15535 4.21608 2.85892 4.61608 2.60535C4.7 2.55356 4.75 2.46071 4.75 2.36249V1.21428C4.75 1.10713 4.6375 1.03928 4.54286 1.08571C2.04465 2.34285 0.321432 4.91606 0.285718 7.89821C0.235718 12.1625 3.72322 15.7053 7.98572 15.7143C12.2536 15.7232 15.7143 12.2625 15.7143 7.99285C15.7143 4.97142 13.9821 2.35535 11.4571 1.08749ZM7.42858 8.85713H8.57143C8.65 8.85713 8.71429 8.79285 8.71429 8.71428V0.428563C8.71429 0.349991 8.65 0.285706 8.57143 0.285706H7.42858C7.35 0.285706 7.28572 0.349991 7.28572 0.428563V8.71428C7.28572 8.79285 7.35 8.85713 7.42858 8.85713Z"
      fill="#282725"
    />
  </svg>
);

const PowerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PowerSvg} {...props} />
);

export default PowerIcon;
