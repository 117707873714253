import React, { useCallback, useContext } from 'react';
import { Typography, Row, Col } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { QRCode } from 'react-qrcode-logo';
import { Buffer } from 'buffer';

import {
  MUTATION_UPDATE_CONTAINER,
  QUERY_GET_CONTAINER,
  QUERY_LIST_CONTAINERS,
} from './queries';

import AssignmentTable from 'components/Tables/AssignmentTable';
import { FIELDS_NAMES } from 'components/Tables/const';
import { useParams } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';
import TypeForm from './components/TypeForm';

const { Title, Text } = Typography;

const CupView = () => {
  const params = useParams();
  const { dbUser } = useContext(UserContext);
  const { data, error, loading } = useQuery(QUERY_GET_CONTAINER, {
    variables: {
      container_id: params.container_id,
    },
  });

  const [updateContainer, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_CONTAINER,
    {
      refetchQueries: [
        { query: QUERY_LIST_CONTAINERS },
        {
          query: QUERY_GET_CONTAINER,
          variables: { container_id: params.container_id },
        },
      ],
    },
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      await updateContainer({
        variables: {
          container_id: params.container_id,
          input: { type_id: values.type_id },
        },
      });
    },
    [updateContainer, params, data],
  );

  if (loading) {
    return <Title level={3}>loading</Title>;
  }

  if (error) {
    return (
      <Title level={3}>
        ERROR: <b>{error.message}</b>
      </Title>
    );
  }

  const container = data.getContainer;

  if (
    !dbUser?.userPermissions.ManageCups &&
    !dbUser?.userPermissions.ViewCups
  ) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10} align={'middle'} style={{ paddingLeft: 10 }}>
      <Col span={12}>
        <Title level={2}>
          View {container.type?.name} &quot;<b>{container.unique_name}</b>&quot;
        </Title>
        <Text
          type="secondary"
          style={{ marginBottom: '10px', fontSize: '10px', display: 'block' }}
        >
          The QR code may look slightly different from the one on the container,
          but they’re the same - promise!
        </Text>
        <QRCode
          value={Buffer.from(`cup_id:${container.container_id}`).toString(
            'base64',
          )}
          ecLevel={'H'}
        />
      </Col>

      {!container.type_id && (
        <Col span={8}>
          <TypeForm loading={updateLoading} handleSubmit={handleSubmit} />
        </Col>
      )}

      <AssignmentTable
        where={{ container_id: { eq: params.container_id } }}
        hiddenColumns={[FIELDS_NAMES.CONTAINER]}
      />
    </Row>
  );
};

export default CupView;
