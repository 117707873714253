import React, { useContext, useMemo, useState } from 'react';
import { Avatar, Layout, Menu, Popover } from 'antd';
import { useLocation } from 'react-router-dom';

import './Sidebar.sass';
import ROUTES from '../../constants/routes';
import { UserContext } from '../../contexts/UserContext';
import { LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import auth from '../../services/Auth/Firebase';
import { MenuItem } from './sidebar-types';
import { getNavigationItems } from './GetNavigationItems';
import PowerIcon from '../Icons/PowerIcon';
import { UserInfo } from './UserInfo';

const { Sider } = Layout;

const Sidebar = () => {
  const SIDEBAR_STATE_KEY = 'is_sidebar_collapsed';
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_STATE_KEY) === 'true',
  );

  const selectedKeys = [];
  for (const key in ROUTES) {
    const routePath = (ROUTES as any)[key];
    if (location.pathname.startsWith(routePath)) {
      selectedKeys.push(routePath);
    }
  }

  const user = useContext(UserContext);
  const { dbUser } = user;

  const menuItems: MenuItem[] = useMemo(
    () => getNavigationItems(isCollapsed, user),
    [isCollapsed, user],
  );

  const menu = dbUser ? (
    <Menu
      className="sidebar-menu"
      theme="light"
      mode="inline"
      selectedKeys={selectedKeys}
      items={menuItems}
    />
  ) : null;

  function toggleCollapse() {
    setIsCollapsed((prevState) => {
      const newState = !prevState;
      localStorage.setItem(SIDEBAR_STATE_KEY, newState ? 'true' : 'false');
      return newState;
    });
  }

  const userInfoItems = (
    <div className="popover-user-info-drop-down">
      <UserInfo currentUser={dbUser} />
      <a
        rel="noopener noreferrer"
        href="#/"
        onClick={async (e) => {
          e.preventDefault();
          await auth.signOut();
        }}
      >
        <PowerIcon />
        <span>Logout</span>
      </a>
    </div>
  );

  return (
    <Sider
      className="sidebar"
      trigger={null}
      collapsible={true}
      collapsed={isCollapsed}
      width={230}
    >
      <div className={`sidebar-header ${isCollapsed ? 'collapsed' : ''}`}>
        {isCollapsed ? (
          <img
            className="sidebar__logo-small"
            src="/images/Usefull_logo_small.png"
            alt="USEFULL logo"
          />
        ) : (
          <img
            className="sidebar__logo"
            src="/images/Usefull_logo.png"
            alt="USEFULL logo"
          />
        )}
        <Popover
          className="popover-user-info"
          placement="right"
          trigger="click"
          content={userInfoItems}
        >
          <Avatar size={32} icon={<UserOutlined />} />
        </Popover>
      </div>
      {menu}
      <SidebarTrigger onClick={toggleCollapse} isCollapsed={isCollapsed} />
    </Sider>
  );
};

interface ISidebarTrigger {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isCollapsed: boolean | undefined;
}

const SidebarTrigger = ({ onClick, isCollapsed }: ISidebarTrigger) => {
  return (
    <div className="sidebar-trigger" onClick={onClick}>
      {isCollapsed ? (
        <RightOutlined className="sidebar-trigger-icon" />
      ) : (
        <LeftOutlined className="sidebar-trigger-icon" />
      )}
    </div>
  );
};

export default Sidebar;
