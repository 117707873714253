import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';

import BulkActions from './BulkActions';
import usePagination from './usePagination';
import _ from 'lodash';
import useFilter, { DOUBLE_VALUE_DELIMETER } from './useFilter';

function getWhereOperation(column: any) {
  return column && column.filterOperation ? column.filterOperation : 'in';
}

function getWhereValue(column: any, selectedKeys: any[]) {
  switch (column.filterOperation) {
    case 'isNull':
      return Boolean(selectedKeys[0]);

    case 'contains':
    case 'fullNameContains':
      return selectedKeys[0];

    default:
      return selectedKeys;
  }
}

function getWhereFilter(column: any, value: any) {
  const result: any = {};
  let link = result;

  if (column.filterSubField) {
    column.filterSubField.forEach((key: string) => {
      link[key] = {};
      link = link[key];
    });
  }
  let operation = getWhereOperation(column);
  let resultValue = value;
  if (operation === 'between') {
    // a filter with a BETWEEN operation has a single array element that includes two values separated by a delimiter
    // so need to separate this element with a delimiter
    // in the output we have the start and end value of the range
    resultValue = value[0].split(DOUBLE_VALUE_DELIMETER);
    if (!resultValue[0]) {
      // if there is no sart value, use LT operation
      operation = 'lt';
      resultValue.shift();
    } else if (!resultValue[1]) {
      // if there is no end value, use GT operation
      operation = 'gt';
      resultValue.pop();
    }
    if (resultValue.join('') === '') return result;
    resultValue = resultValue.map((item: string) => Number(item));
    if (operation === 'between') {
      // LT works like `less than`, GT works like `more than`
      // but BETWEEN works as `more than or equal and less than or equal`
      // so we do little tricky)
      resultValue[0] += 0.0000001;
      resultValue[1] -= 0.0000001;
    }
  }
  link[operation] = getWhereValue(column, resultValue);
  return result;
}

const CCCTable = forwardRef(
  (
    {
      rowKey,
      query,
      queryKey,
      where: parentScopeWhere,
      actions = [],
      onChange,
      columns,
      scrollOffsetY = 225,
      ...props
    }: any,
    ref: any,
  ) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const {
      getColumnSearchProps,
      getColumnRadioFilterProps,
      getColumnNumberSearch,
    } = useFilter(columns);

    const tableWrapRef = useRef<any>({ current: null });
    const pagination = usePagination();
    const [scroll, setScroll] = useState<any>();
    const [order, setOrder] = useState<any>();
    const [where, setWhere] = useState<any>();
    const { data, loading, error, refetch } = useQuery(query, {
      variables: {
        pagination: pagination.getPagination(),
        order: order ? [order] : undefined,
        where: {
          ...parentScopeWhere,
          ...where,
        },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
      data && !error && pagination.handleTotal(data[queryKey].total);
    }, [data, pagination, error, queryKey]);

    const dataSource = useMemo(
      () => (data && !error ? data[queryKey].list : []),
      [data, error, queryKey],
    );

    useImperativeHandle(ref, () => ({
      loading,
      selectedRowKeys,
      setSelectedRowKeys,

      pagination,
      refetch,

      // helpers
      getColumnSearchProps,
      getColumnNumberSearch,
      getColumnRadioFilterProps,
    }));

    useEffect(() => {
      const calculateScroll = (timeout = 0) =>
        setTimeout(
          () =>
            setScroll({
              y: Math.max(240, window.innerHeight - scrollOffsetY),
              x: 'max-content',
            }),
          timeout,
        );

      calculateScroll();
      window.addEventListener('orientationchange', calculateScroll as any);
      return () =>
        window.removeEventListener('orientationchange', calculateScroll as any);
    }, []);
    return (
      <div ref={tableWrapRef}>
        <BulkActions
          rows={
            data
              ? selectedRowKeys.map((id: number) =>
                  dataSource.find((c: any) => c[rowKey] === id),
                )
              : []
          }
          actions={actions}
        />
        <Table
          tableLayout="fixed"
          rowKey={rowKey}
          pagination={pagination.pagination}
          dataSource={dataSource}
          loading={loading}
          rowSelection={
            actions.filter((a: any) => !!a.run || !!a.runItem)?.length
              ? {
                  selectedRowKeys,
                  onChange: setSelectedRowKeys,
                  hideDefaultSelections: true,
                }
              : undefined
          }
          columns={columns}
          scroll={scroll}
          onChange={useCallback(
            (p: any, _where: any, sorter: any) => {
              setSelectedRowKeys([]);
              setWhere(
                Object.keys(_where).reduce((accumulator: any, key: any) => {
                  const column = columns.find(
                    ({ dataIndex, key: k }: any) =>
                      dataIndex === key || k === key,
                  );

                  if (Array.isArray(_where[key]) && _where[key]?.length) {
                    if (!_where[key].includes(null)) {
                      accumulator[key] = getWhereFilter(column, _where[key]);
                    } else if (_where[key]?.length > 1) {
                      const firstArg = {},
                        secondArg = {};

                      _.set(
                        firstArg,
                        key,
                        getWhereFilter(
                          column,
                          _where[key].filter((value: any) => value),
                        ),
                      );
                      _.set(secondArg, key, { isNull: true });
                      const orStatement = [firstArg, secondArg];

                      if (accumulator.or) {
                        accumulator.and = [
                          { or: accumulator.or },
                          { or: orStatement },
                        ];
                        delete accumulator.or;
                      } else {
                        accumulator.or = orStatement;
                      }
                    } else {
                      accumulator[key] = { isNull: true };
                    }
                  }
                  accumulator = Object.keys(accumulator).reduce(
                    (calc: Record<string, string>, key) => {
                      const fields = key.split('|');
                      if (fields?.length === 1) {
                        _.set(calc, key, accumulator[key]);
                      } else if (fields?.length > 1) {
                        const resultObject: any = [];
                        fields.forEach((field) => {
                          const object = {};
                          _.set(object, field, accumulator[key]);
                          resultObject.push(object);
                        });
                        _.set(calc, 'or', resultObject);
                      }
                      return calc;
                    },
                    {},
                  );
                  return accumulator;
                }, {}),
              );

              setOrder(
                sorter.column
                  ? {
                      field:
                        typeof sorter.column.sorter === 'boolean'
                          ? Array.isArray(sorter.column.dataIndex)
                            ? sorter.column.dataIndex.join('.')
                            : sorter.column.dataIndex
                          : sorter.column.sorter,
                      order: sorter.order === 'descend' ? 'DESC' : 'ASC',
                    }
                  : null,
              );
              pagination.handleChange(p);

              onChange && onChange.call(null, p, _where, sorter);

              refetch();
            },
            [pagination, columns, refetch, onChange],
          )}
          {...props}
        />
      </div>
    );
  },
);

CCCTable.displayName = 'CCCTable';

export default CCCTable;
