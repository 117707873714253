import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const ContainerSvg = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.20001 10H9.83334L10.15 5.33333H3.86668L4.20001 10ZM3.76668 4H10.25L10.3 3.16667L8.43334 1.33333H5.56668L3.70001 3.16667L3.76668 4ZM2.50001 5.13333L0.333344 2.96667L1.26668 2.03333L2.33334 3.1L2.30001 2.7L5.00001 0H9.00001L11.7 2.7L11.6667 3.1L12.7333 2.03333L13.6667 2.96667L11.5 5.13333H2.50001ZM2.96668 11.3333L2.50001 5.13333H11.5L11.0333 11.3333H2.96668Z"
      fill="#605E55"
    />
  </svg>
);

const ContainerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ContainerSvg} {...props} />
);

export default ContainerIcon;
