import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const HomeSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99999 10.6667H3.99999V6.66667H7.99999V10.6667H9.99999V4.66667L5.99999 1.66667L1.99999 4.66667V10.6667ZM0.666656 12V4L5.99999 0L11.3333 4V12H6.66666V8H5.33332V12H0.666656Z"
      fill="#028389"
    />
  </svg>
);

const HomeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HomeSvg} {...props} />
);

export default HomeIcon;
