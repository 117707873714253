import React, { useCallback, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Col, Popconfirm, Row, Typography, Button } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  QUERY_GET_LOCATION,
  QUERY_LIST_LOCATION_ROLES,
  MUTATION_UPDATE_LOCATION,
  MUTATION_UPDATE_LOCATION_ROLE,
  MUTATION_SYNC_LOCATION_CAPABILITY,
} from './queries';
import LocationForm from './components/LocationForm';
import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import AssignmentTable from 'components/Tables/AssignmentTable';
import routes from 'constants/routes';

import { FULL_DATE } from 'constants/dateformats';
import stripTypename from 'services/stripTypename';
import { UserContext } from 'contexts/UserContext';
import { FIELDS_NAMES } from 'components/Tables/const';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

export default (() => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const { location_id } = useParams();

  const { setTableRef, refetch } = useTable();
  const { data, loading, error } = useQuery(QUERY_GET_LOCATION, {
    variables: { location_id },
  });
  const [updateLocation] = useMutation(MUTATION_UPDATE_LOCATION);
  const [syncLocationCapability] = useMutation(
    MUTATION_SYNC_LOCATION_CAPABILITY,
  );
  const [updateLocationRole] = useMutation(MUTATION_UPDATE_LOCATION_ROLE);
  const submit = useCallback(
    async ({ capabilities, ...input }: any) => {
      await updateLocation({
        variables: {
          location_id,
          input,
        },
        refetchQueries: [
          {
            query: QUERY_GET_LOCATION,
            variables: {
              location_id,
            },
          },
        ],
      });
      await syncLocationCapability({
        variables: {
          location_id,
          input: capabilities.map((capability_id: string) => ({
            capability_id,
            location_id,
          })),
        },
      });
      navigate(routes.LOCATIONS);
    },
    [navigate, syncLocationCapability, updateLocation, location_id],
  );

  const locationRoleColumns: any[] = [
    {
      title: 'Location',
      dataIndex: ['location', 'name'],
      render: (loc_name: string) => {
        return loc_name || '- All -';
      },
    },
    {
      title: 'Full Name',
      dataIndex: ['user', 'full_name'],
    },
    {
      title: 'Role',
      dataIndex: ['role', 'name'],
    },
    {
      title: 'Assigned By',
      dataIndex: ['assigned_by', 'full_name'],
    },
    {
      title: 'Assigned On',
      dataIndex: 'assigned_on',
      render: (assigned_on: string) =>
        assigned_on ? dayjs(assigned_on).format(FULL_DATE) : null,
    },

    {
      title: 'Disabled On',
      width: 100,
      dataIndex: 'disabled_on',
      render: (disabled_on: string) =>
        disabled_on ? dayjs(disabled_on).format(FULL_DATE) : null,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: any) => (
        <Popconfirm
          title="Are you sure?"
          onConfirm={async () => {
            const input: any = {};
            for (const key in record) {
              if (typeof record[key] !== 'object') {
                input[key] = record[key];
              }
            }
            delete input.location_role_id;

            await updateLocationRole({
              variables: {
                location_role_id: record.location_role_id,
                input: {
                  ...stripTypename(input),
                  ...(!record.disabled_on
                    ? {
                        disabled_on: new Date(),
                        disabled_by_id: dbUser ? dbUser.user_id : null,
                      }
                    : {
                        disabled_on: null,
                        disabled_by_id: null,
                      }),
                },
              },
            });
            refetch();
          }}
        >
          <a>{record.disabled_on ? 'Activate' : 'Disable'}</a>
        </Popconfirm>
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageLocations) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={15} style={{ paddingLeft: 5 }}>
      <Col span={24}>
        {!loading && !error ? (
          <LocationForm location={data.getLocation} handleSubmit={submit} />
        ) : error ? (
          'Error:' + error.message
        ) : (
          'loading...'
        )}
      </Col>

      <Col span={24} style={{ marginTop: 50 }}>
        <Row justify="end">
          <Col span={12}>
            <Typography.Title level={3}>Location Roles</Typography.Title>
          </Col>
          <Col span={12}>
            <Row justify="end">
              {dbUser?.isCorporateAdmin ? null : (
                <Link
                  to={{
                    pathname: '/locations/role/create',
                  }}
                  state={{
                    location: data ? data.getLocation : { location_id },
                  }}
                >
                  <Button type="primary">Create Location Role</Button>
                </Link>
              )}
            </Row>
          </Col>
        </Row>
        <CCCTable
          ref={setTableRef}
          rowKey="location_role_id"
          query={QUERY_LIST_LOCATION_ROLES}
          queryKey="listLocationRole"
          columns={locationRoleColumns}
          where={{
            or: [
              { location_id: { eq: location_id } },
              { location_id: { isNull: true } },
            ],
          }}
        />

        <AssignmentTable
          where={{ from_location_id: { eq: location_id } }}
          hiddenColumns={[FIELDS_NAMES.FROM_LOCATION]}
        />
      </Col>
    </Row>
  );
}) as any;
