import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import CCCTable from 'components/CCCTable/CCCTable';
import useTable from 'components/CCCTable/useTable';
import routes from 'constants/routes';

import {
  QUERY_LIST_TOAST_MESSAGES,
  MUTATION_REMOVE_TOAST_MESSAGE,
} from './queries';
import { useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { FULL_DATE } from '../../../constants/dateformats';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const { setTableRef, mounted, refetch, getColumnSearchProps } = useTable();
  const [removeToastMessage] = useMutation(MUTATION_REMOVE_TOAST_MESSAGE);

  const columns = useMemo(() => {
    return [
      {
        title: 'Message',
        dataIndex: 'message',

        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('message')),

        render: (name: string, record: any) => (
          <Link to={routes.TOAST_MESSAGES + '/' + record.message_id + '/edit'}>
            {name}
          </Link>
        ),
        width: 180,
      },
      {
        title: 'Added By',
        dataIndex: ['added_by', 'first_name'],
        sorter: true,
        render: (_: any, record: any) => record.added_by.full_name,
        width: 180,
      },
      {
        title: 'Added On',
        dataIndex: 'added_on',
        sorter: true,
        render: (date: any) => (date ? dayjs(date).format(FULL_DATE) : ' - '),
        width: 180,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, record: any) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={async () => {
                await removeToastMessage({
                  variables: {
                    message_id: record.message_id,
                  },
                });
                refetch();
              }}
            >
              <a href="/#">Remove</a>
            </Popconfirm>
          );
        },
      },
    ];
  }, [mounted]);

  const actions = [
    {
      label: 'Create Toast Message',
      type: 'primary',
      to: routes.TOAST_MESSAGES + '/create',
    },
  ];

  if (dbUser && !dbUser.userPermissions.ManageSettings) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      query={QUERY_LIST_TOAST_MESSAGES}
      queryKey="listToastMessage"
      rowKey="message_id"
      columns={columns}
      actions={actions}
    />
  );
};

export default Index;
