import React, { useCallback, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  QUERY_GET_TOAST_MESSAGE,
  QUERY_LIST_TOAST_MESSAGES,
  MUTATION_UPDATE_TOAST_MESSAGE,
} from './queries';
import ToastMessageForm from './components/ToastMessageForm';
import routes from 'constants/routes';
import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';
import Loader from '../../../components/Loader/Loader';

const ToastEdit = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const { message_id } = useParams();

  const { data, loading, error } = useQuery(QUERY_GET_TOAST_MESSAGE, {
    variables: { message_id },
  });
  const [updateToastMessage] = useMutation(MUTATION_UPDATE_TOAST_MESSAGE);

  const submit = useCallback(
    async (input: any) => {
      await updateToastMessage({
        variables: {
          message_id,
          input,
        },
        refetchQueries: [
          {
            query: QUERY_LIST_TOAST_MESSAGES,
          },
          {
            query: QUERY_GET_TOAST_MESSAGE,
            variables: {
              message_id,
            },
          },
        ],
      });
      navigate(routes.TOAST_MESSAGES);
    },
    [navigate, updateToastMessage, message_id],
  );

  if (loading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageSettings) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={15} style={{ paddingLeft: 10 }}>
      <Col span={24}>
        {!loading && !error ? (
          <ToastMessageForm
            toastMessage={data.getToastMessage}
            handleSubmit={submit}
          />
        ) : error ? (
          'Error:' + error.message
        ) : (
          'loading...'
        )}
      </Col>
    </Row>
  );
};

export default ToastEdit;
