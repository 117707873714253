import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Typography, Row, Col, Select } from 'antd';

import LocationSearchSelect from 'components/Select/LocationSearchSelect';
import UserSearchSelect from 'components/Select/UserSearchSelect';
import RoleSelect from 'components/Select/RoleSelect';

import { LocationRoles } from 'constants/LocationRoles';
const { Title } = Typography;

const LocationRoleForm = ({
  handleSubmit,
  locationRole,
  location,
  user,
}: any) => {
  const [roleId, setRoleId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const mode = locationRole ? 'update' : 'create';

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(locationRole || {});
    if (location) {
      form.setFieldsValue({
        location_id: location.location_id,
      });
    }
    if (user) {
      form.setFieldsValue({
        user_id: user.user_id,
      });
    }
  }, [form, locationRole, location, user]);

  const submit = useCallback(
    async (values: any) => {
      setLoading(true);
      const location_id = form.getFieldValue('location_id');
      await handleSubmit({
        ...values,
        location_id: location_id === 'all' ? null : location_id,
      });
      setLoading(false);
    },
    [form, handleSubmit],
  );

  return (
    <Row gutter={10} style={{ paddingLeft: 10 }}>
      <Form
        form={form}
        name="location_role_create"
        layout="vertical"
        onFinish={submit}
      >
        <Row>
          <Col span={24}>
            <Title level={2}>
              {mode === 'create'
                ? 'Create Location Role'
                : `Update Location Role`}
            </Title>
          </Col>
          <Col span={24} style={{ margin: '20px 0' }}>
            <Form.Item
              label="Role"
              name="role_id"
              rules={[{ required: true, message: 'Please select role!' }]}
            >
              <RoleSelect
                onChange={(role: string) => {
                  setRoleId(role);

                  if (
                    form.getFieldValue('location_id') === 'all' &&
                    +role !== LocationRoles.CCCStaff
                  ) {
                    form.setFieldsValue({
                      location_id: null,
                    });
                  }

                  if (
                    +role === LocationRoles.CCCStaff &&
                    form.getFieldValue('location_id') !== 'all'
                  ) {
                    form.setFieldsValue({
                      location_id: 'all',
                    });
                  }
                }}
                placeholder="Select Role"
              />
            </Form.Item>

            <Form.Item
              label="User"
              name="user_id"
              rules={[{ required: true, message: 'Please select User!' }]}
              initialValue={user?.user_id}
            >
              <UserSearchSelect placeholder="Select User" />
            </Form.Item>

            <Form.Item
              label="Location"
              name="location_id"
              rules={[{ required: true, message: 'Please select location!' }]}
            >
              <LocationSearchSelect
                key={roleId} // reload select when role changes
                allOption={LocationRoles.CCCStaff === +roleId} // allow all option onto for CCC Staff
                disableChildren={LocationRoles.CCCStaff === +roleId}
                placeholder="Select Location"
              >
                {location ? (
                  <Select.Option
                    key={location.location_id}
                    value={location.location_id}
                  >
                    {location.name}
                  </Select.Option>
                ) : null}
              </LocationSearchSelect>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {mode === 'create' ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default LocationRoleForm;
