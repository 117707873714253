import React, { useCallback, useContext, useMemo, useState } from 'react';
import numeral from 'numeral';
import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import routes from '../../../constants/routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  MUTATION_UPDATE_CORPORATE_CLIENT,
  QUERY_LIST_CORPORATE_CLIENTS_TABLE,
  QUERY_ONE_CORPORATE_CLIENT,
} from '../../../queries/CorporateClientsQueries';
import { ColumnType } from 'antd/lib/table';
import { FIELDS_NAMES } from '../../../components/Tables/const';
import moment from 'moment/moment';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import CorporateClientStatusSelect from '../../../components/Select/CorporateClientStatusSelect';
import { CURRENCY_FORMAT } from '../../../constants/numberformats';
import { PaymentProcessors } from '../../../constants/PaymentProcessors';
import Loader from '../../../components/Loader/Loader';
import { MUTATION_INVITE_USER } from '../../../queries/UserQueries';
import Permissions from '../../../constants/Permissions';

const { Title } = Typography;

const AssignmentEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalStatusMessage, setModalStatusMessage] = useState<string | null>(
    null,
  );
  const [isInvitationLoading, setIsInvitationLoading] = useState(false);
  const [form] = Form.useForm();
  const [inviteUserForm] = Form.useForm();

  const { data, error, loading } = useQuery(QUERY_ONE_CORPORATE_CLIENT, {
    variables: {
      corporate_client_id: params.corporate_client_id,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateCorporateClient] = useMutation(
    MUTATION_UPDATE_CORPORATE_CLIENT,
    {
      refetchQueries: [{ query: QUERY_LIST_CORPORATE_CLIENTS_TABLE }],
    },
  );

  const [inviteUser] = useMutation(MUTATION_INVITE_USER);

  const handleEditSubmit = useCallback(
    async (values: any) => {
      setSaving(true);
      await updateCorporateClient({
        variables: {
          corporate_client_id: params.corporate_client_id,
          input: { ...values, status_id: Number(values.status_id) },
        },
      });

      setSaving(false);
      setTimeout(() => {
        navigate(routes.CORPORATE_CLIENTS);
      }, 100);
    },
    [navigate, data, updateCorporateClient, params],
  );

  const adminsColumns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: FIELDS_NAMES.NAME,
        dataIndex: 'user',
        key: 'user.full_name',
        render: (user: Record<string, any>) =>
          dbUser?.userPermissions.ManageUsers ? (
            <Link to={routes.USERS + '/' + user.user_id}>{user.full_name}</Link>
          ) : (
            user.full_name
          ),
      },
      {
        title: FIELDS_NAMES.EMAIL,
        dataIndex: ['user', 'email'],
        key: 'user.email',
      },
      {
        title: FIELDS_NAMES.ADDED_ON,
        dataIndex: 'created_on',
        key: 'created_on',
        render: (data: string) =>
          data ? moment(data).format('LLL') : '- not set -',
      },
    ];
  }, []);

  const membershipsColumns: ColumnType<any>[] = useMemo(() => {
    const extraColumns = dbUser?.isCorporateAdmin
      ? []
      : [
          {
            title: FIELDS_NAMES.MAX_CONTAINERS_PER_MONTH,
            dataIndex: 'max_containers_per_month',
            key: 'max_containers_per_month',
          },
          {
            title: FIELDS_NAMES.RENT_DURATION_MAX_DAYS,
            dataIndex: 'rent_duration_max_days',
            key: 'rent_duration_max_days',
          },
          {
            title: FIELDS_NAMES.WARNING_EMAIL_AMOUNT,
            dataIndex: 'warning_email_amount',
            key: 'warning_email_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
          {
            title: FIELDS_NAMES.LOCKED_ACCOUNT_AMOUNT,
            dataIndex: 'locked_account_amount',
            key: 'locked_account_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
        ];

    return [
      {
        title: FIELDS_NAMES.NAME,
        dataIndex: ['membershipLevel', 'name'],
        key: 'membershipLevel.name',
      },
      {
        title: FIELDS_NAMES.ROLE,
        dataIndex: ['clientRole', 'name'],
        key: 'clientRole.name',
      },
      {
        title: FIELDS_NAMES.RENT_DURATION_DAYS,
        dataIndex: 'rent_duration_days',
        key: 'rent_duration_days',
      },
      {
        title: FIELDS_NAMES.MAX_CHECKOUTS_PER_DAY,
        dataIndex: 'max_checkouts_per_day',
        key: 'max_checkouts_per_day',
      },
      {
        title: FIELDS_NAMES.MAX_CONTAINERS_AT_A_TIME,
        dataIndex: 'max_containers_at_a_time',
        key: 'max_containers_at_a_time',
      },
      ...extraColumns,
    ];
  }, [dbUser]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Title level={3}>
        ERROR: <b>{error.message}</b>
      </Title>
    );
  }

  const corpClient = data.getCorporateClient;
  const link = `${routes.TV_HOSTED_PAGE}/${corpClient.uuid}`;

  if (
    !dbUser?.userPermissions.ManageCorporateClients ||
    (dbUser?.isCorporateAdmin &&
      !dbUser.userCorporateClients.find(
        (c) => c.corporate_client_id === Number(params.corporate_client_id),
      ))
  ) {
    return <PermissionsAlert />;
  }

  const validateDigits = (rule: any, value: string) => {
    const regExp = /^[0-9]{4}$/; // Regex for exactly 4 digits
    if (value && !regExp.test(value)) {
      return Promise.reject('Please enter exactly 4 digits');
    }
    return Promise.resolve();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    inviteUserForm.resetFields();
    setModalStatusMessage(null);
  };

  const handleInviteUser = async (values: {
    email: string;
    permissions: string[];
  }) => {
    setIsInvitationLoading(true);
    setModalStatusMessage(null);

    try {
      const response = await inviteUser({
        variables: {
          grantedPermissions: values.permissions,
          inviteeEmail: values.email,
          corporateClientId: corpClient.corporate_client_id,
        },
      });

      setIsInvitationLoading(false);
      if (response?.data) {
        setModalStatusMessage('Success! Invitation sent');
        setTimeout(() => {
          handleCancel();
        }, 1300);
      }
    } catch (err) {
      console.error('Error creating invite:', err);
    } finally {
      setIsInvitationLoading(false);
    }
  };

  const items = [
    {
      key: '1',
      label: 'General',
      children: (
        <Descriptions column={1} bordered>
          <Descriptions.Item>
            <Form
              form={form}
              layout="inline"
              name="name"
              onFinish={handleEditSubmit}
              initialValues={{
                name: corpClient.name,
                status_id: corpClient?.status_id?.toString(),
                lock_code: corpClient?.lock_code,
                locked: corpClient?.locked,
              }}
              style={{ marginTop: -10, marginBottom: -10 }}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    min: 1,
                    message: 'cannot be empty',
                  },
                ]}
                style={{ marginBottom: 10, width: '300px' }}
              >
                <Input
                  placeholder={corpClient.name}
                  disabled={saving || !dbUser?.userPermissions.DangerZone}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status_id"
                rules={[{ required: true, message: 'Please select status!' }]}
                style={{ marginBottom: 10, width: '200px' }}
              >
                <CorporateClientStatusSelect
                  placeholder="Select status"
                  onChange={(val: any) => form.setFieldValue('status_id', val)}
                  disabled={!dbUser?.userPermissions.DangerZone}
                />
              </Form.Item>
              <Form.Item
                name="locked"
                label="Locked"
                valuePropName="checked"
                style={{ marginBottom: 10, marginLeft: 50, width: '80px' }}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="lock_code"
                label="Lock Code"
                rules={[
                  ({ getFieldValue }) => ({
                    type: 'string',
                    max: 4,
                    required: !!getFieldValue('locked'),
                    message: 'Please set the code if locked is checked!',
                  }),
                  {
                    validator: validateDigits, // Custom validator for 4 digits
                  },
                ]}
              >
                <Input
                  maxLength={4}
                  placeholder={corpClient.lock_code}
                  disabled={saving || !dbUser?.userPermissions.DangerZone}
                  style={{ marginBottom: 10, width: '100px' }}
                />
              </Form.Item>
              <Button
                loading={saving}
                type="primary"
                htmlType="submit"
                disabled={!dbUser?.userPermissions.DangerZone}
              >
                Update
              </Button>
            </Form>
          </Descriptions.Item>
          {!!corpClient.config_parsed.payment_config
            .default_payment_processor_id && (
            <Descriptions.Item label="Campus Card Provider">
              {
                PaymentProcessors[
                  corpClient.config_parsed.payment_config
                    .default_payment_processor_id
                ]
              }
            </Descriptions.Item>
          )}
          <Descriptions.Item label="TV dashboard URL">
            <span style={{ marginRight: 50 }}>
              {process.env.REACT_APP_URI}
              {link}
            </span>
            <Link to={link + '?admin=true'}>Preview</Link>

            <Link
              to={`${link}/share?admin=true`}
              target="_blank"
              style={{ marginLeft: 50 }}
            >
              Generate Shareable Image
            </Link>
          </Descriptions.Item>
        </Descriptions>
      ),
    },
    {
      key: '2',
      label: 'Memberships',
      children: (
        <Descriptions column={1} bordered>
          {corpClient?.clientLevels?.length && (
            <Descriptions.Item label="Memberships">
              <Table
                tableLayout="fixed"
                rowKey={(record) => record.client_level_id}
                dataSource={corpClient.clientLevels}
                columns={membershipsColumns}
                pagination={false}
              />
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
    },
    {
      key: '3',
      label: 'Team',
      children: (
        <Descriptions column={1} bordered>
          {dbUser?.userPermissions.InviteUsers && (
            <span>
              <Button
                type="primary"
                onClick={showModal}
                disabled={!dbUser?.userPermissions.InviteUsers}
              >
                Invite Admin User
              </Button>
              <Modal
                title="Invite User"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // Custom footer with Submit button
              >
                <Form
                  form={inviteUserForm}
                  layout="vertical"
                  onFinish={handleInviteUser}
                  initialValues={{
                    email: '',
                    permissions: [
                      Permissions.AdminLogin,
                      Permissions.ViewCups,
                      Permissions.ViewLocations,
                      Permissions.ViewAssignments,
                      Permissions.BasicReports,
                      Permissions.ManageCorporateClients,
                    ],
                  }}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: 'Please enter an email!' },
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter email" />
                  </Form.Item>
                  <Form.Item label="Permissions" name="permissions">
                    <Select
                      mode="tags"
                      placeholder="Select permissions"
                      defaultValue={[
                        Permissions.AdminLogin,
                        Permissions.ViewCups,
                        Permissions.ViewLocations,
                        Permissions.ViewAssignments,
                        Permissions.BasicReports,
                        Permissions.ManageCorporateClients,
                      ]}
                      disabled={!dbUser?.userPermissions.DangerZone}
                    >
                      {Object.entries(Permissions).map(([key, value]) => (
                        <Select.Option key={value} value={value}>
                          {key.replace(/([A-Z])/g, ' $1').trim()}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                      {isInvitationLoading ? <Spin size="small" /> : 'Invite'}
                    </Button>
                  </Form.Item>
                </Form>
                {modalStatusMessage && (
                  <div
                    style={{
                      marginTop: '10px',
                      color: modalStatusMessage.startsWith('Success')
                        ? 'green'
                        : 'red',
                    }}
                  >
                    {modalStatusMessage}
                  </div>
                )}
              </Modal>
            </span>
          )}
          {corpClient?.userCorporateClients?.length && (
            <Descriptions.Item label="Corporate Client Admins">
              <Table
                tableLayout="fixed"
                rowKey={(record) => record.user.user_id}
                dataSource={corpClient.userCorporateClients}
                columns={adminsColumns}
                pagination={false}
              />
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
    },
  ];

  return (
    <div style={{ paddingLeft: 10 }}>
      <Title level={4}>{corpClient.name}</Title>
      <Tabs items={items} />
    </div>
  );
};

export default AssignmentEdit;
