import React, { useState } from 'react';
import { Button, Dropdown, Flex } from 'antd';
import { Link } from 'react-router-dom';

const BulkActions = ({ rows, actions }: any) => {
  const [loading, setLoading] = useState<any>({});

  return (
    <Flex gap="small" justify="flex-end" style={{ marginBottom: 20 }}>
      {actions.map((action: any, index: number) => {
        if (action.to) {
          return (
            <Link key={action.label} to={action.to}>
              <Button type={action.type} danger={action.danger}>
                {action.label}
              </Button>
            </Link>
          );
        }
        const isDisabled =
          (action.checkDisabled && action.checkDisabled(rows)) ||
          !rows.length ||
          (action.onlyOne && rows.length > 1);

        const onClick = async () => {
          setLoading((l: any) => ({
            ...l,
            [index]: true,
          }));
          await action.run(rows);
          setLoading((l: any) => ({
            ...l,
            [index]: false,
          }));
        };

        return action.isDropdown ? (
          <Flex key={action.label}>
            <Dropdown.Button
              menu={{
                items: action.dropdownItems,
                onClick: async ({ key }) => {
                  setLoading((l: any) => ({
                    ...l,
                    [index]: true,
                  }));
                  await action.runItem(rows, key);
                  setLoading((l: any) => ({
                    ...l,
                    [index]: false,
                  }));
                },
              }}
              disabled={isDisabled}
              loading={loading[index]}
              type={action.type}
              trigger={['hover']}
            >
              {action.label}
            </Dropdown.Button>
          </Flex>
        ) : (
          <Button
            key={action.label}
            type={action.type}
            loading={loading[index]}
            onClick={onClick}
            disabled={isDisabled}
            danger={action.danger}
          >
            {action.label}
          </Button>
        );
      })}
    </Flex>
  );
};

export default BulkActions;
