import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { FIELDS_NAMES } from './const';
import { Button, notification, Table, Typography } from 'antd';
import { CURRENCY_FORMAT } from '../../constants/numberformats';
import numeral from 'numeral';
import { useMutation } from '@apollo/client';
import { MUTATION_VOID_FEE_LEDGER_RECORD } from '../../queries/FeeLedgerQueries';
import { QUERY_ONE_ASSIGNMENT } from '../../queries/AssignmentsQueries';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { ColumnType } from 'antd/lib/table';
import { UserContext } from '../../contexts/UserContext';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const { Text } = Typography;
interface IFeeLedgerTableProps {
  data: any;
  query: any;
  isAssignmentIdShown?: boolean;
}
const FeeLedgerTable = ({
  data,
  query,
  isAssignmentIdShown,
}: IFeeLedgerTableProps) => {
  const { dbUser } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();
  const [voidFeeLedger] = useMutation(MUTATION_VOID_FEE_LEDGER_RECORD, {
    refetchQueries: [query, QUERY_ONE_ASSIGNMENT],
  });

  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      ...(isAssignmentIdShown
        ? [
            {
              title: FIELDS_NAMES.ASSIGNMENT_ID,
              dataIndex: 'assignment_id',
              sorter: (
                recordA: { assignment_id: number },
                recordB: { assignment_id: number },
              ) => recordA.assignment_id - recordB.assignment_id,
              render: (id: number) =>
                dbUser?.userPermissions.ManageAssignments ||
                dbUser?.userPermissions.ViewAssignments ? (
                  <Link to={routes.ASSIGNMENTS + '/' + id}>{id}</Link>
                ) : (
                  id
                ),
              key: 'assignment_id',
            },
          ]
        : []),
      {
        title: FIELDS_NAMES.CONTAINER,
        dataIndex: ['assignment', 'container', 'unique_name'],
        sorter: (recordA: any, recordB: any) =>
          recordA.assignment.container.unique_name.localeCompare(
            recordB.assignment.container.unique_name,
          ),
        render: (name: string, record: any) =>
          dbUser?.userPermissions.ManageCups ||
          dbUser?.userPermissions.ViewCups ? (
            <Link
              to={
                routes.CONTAINERS +
                '/' +
                record.assignment?.container?.container_id
              }
            >
              {name}
            </Link>
          ) : (
            name
          ),
        key: 'assignment.container',
      },
      {
        title: FIELDS_NAMES.AMOUNT,
        dataIndex: 'amount',
        sorter: (recordA: { amount: number }, recordB: { amount: number }) =>
          recordA.amount - recordB.amount,
        render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
        key: 'amount',
      },
      {
        title: FIELDS_NAMES.ASSESSED_ON,
        sorter: (
          recordA: { assessed_on: string },
          recordB: { assessed_on: string },
        ) =>
          Number(new Date(recordA.assessed_on)) -
          Number(new Date(recordB.assessed_on)),
        dataIndex: 'assessed_on',
        key: 'assessed_on',
        render: (data: string) =>
          data ? dayjs(data).format('LLL') : '- not set -',
      },
      {
        title: FIELDS_NAMES.PROCESSED_ON,
        sorter: (
          recordA: { processed_on: string },
          recordB: { processed_on: string },
        ) =>
          Number(new Date(recordA.processed_on)) -
          Number(new Date(recordB.processed_on)),
        dataIndex: 'processed_on',
        key: 'processed_on',
        render: (data: string) =>
          data ? dayjs(data).format('LLL') : '- not set -',
      },
      {
        title: FIELDS_NAMES.NOTE,
        dataIndex: 'note',
        render: (note: string) => note || '- not set -',
        key: 'note',
      },
      {
        title: FIELDS_NAMES.ACTIONS,
        dataIndex: 'actions',
        render: (_: any, r: Record<string, any>) =>
          !r.processed_on && r.amount > 0 && !r.is_voided ? (
            <Button
              type={'primary'}
              onClick={async () => {
                await voidFeeLedger({ variables: { record_id: r.record_id } });
                api.success({ message: 'Done!' });
              }}
            >
              Void
            </Button>
          ) : (
            <Text>- not available -</Text>
          ),
      },
    ];
  }, [isAssignmentIdShown]);

  return (
    <>
      {contextHolder}
      <Table
        tableLayout="fixed"
        rowKey={'record_id'}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default FeeLedgerTable;
