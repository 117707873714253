import React, { useCallback, useState } from 'react';
import {
  MUTATION_CHANGE_STATUS_CONTAINER,
  QUERY_CONTAINER_STATUSES,
  QUERY_GET_CONTAINER,
  QUERY_LIST_CONTAINERS,
} from '../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Modal, Select, message } from 'antd';
import { CupStatuses } from 'constants/CupStatuses';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

const ChangeStatusModal = ({ visible, record, onClose }: any) => {
  const [loading, setLoading] = useState(false);
  const [containerStatusChange] = useMutation(MUTATION_CHANGE_STATUS_CONTAINER);
  const { data: statuses, error } = useQuery(QUERY_CONTAINER_STATUSES);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = useForm();

  const handleOk = useCallback(async () => {
    setLoading(true);

    try {
      const values = await form.validateFields();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status_id, ...additionalData } = values;
      const statusChangeRes = await containerStatusChange({
        variables: {
          container_id: record.container_id,
          status_id: values.status_id,
          additionalData,
        },
        refetchQueries: [
          { query: QUERY_LIST_CONTAINERS },
          {
            query: QUERY_GET_CONTAINER,
            variables: { container_id: record.container_id },
          },
        ],
      });
      setLoading(false);

      if (statusChangeRes.data.containerStatusChange.error_message) {
        messageApi.error(
          statusChangeRes.data.containerStatusChange.error_message.message,
        );
      } else {
        onClose(true);
      }
    } catch (e) {
      setLoading(false);
    }
  }, [form, record, onClose, containerStatusChange]);

  function isStatusDisabled(status_id: number) {
    return (
      [CupStatuses.ReadyForDelivery, CupStatuses.CheckedOut].indexOf(
        status_id,
      ) !== -1
    );
  }

  function isStatusShown(status_id: number) {
    return [CupStatuses.New].indexOf(status_id) === -1;
  }

  return (
    <Modal
      title="Change Status"
      open={visible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <Form name="cup_status" form={form} onFinish={handleOk}>
        {contextHolder}
        <Form.Item
          label="Status"
          name="status_id"
          rules={[{ required: true, message: 'Please select status!' }]}
        >
          <Select placeholder="Pick Status">
            {statuses && !error
              ? statuses.listContainerStatus
                  .filter((s: any) => isStatusShown(s.status_id))
                  .map((status: any) => (
                    <Option
                      key={status.status_id}
                      value={status.status_id}
                      disabled={isStatusDisabled(status.status_id)}
                    >
                      {status.name}
                    </Option>
                  ))
              : null}
          </Select>
        </Form.Item>

        {/*{getFieldValue('status_id') === CupStatuses.CheckedOut && (*/}
        {/*    <Form.Item label="User for new checkout">*/}
        {/*      {getFieldDecorator('user_id', {*/}
        {/*        rules: [{ required: true, message: 'Please select user!' }],*/}
        {/*      })(<UserSearchSelect placeholder="Pick User for Assignment" />)}*/}
        {/*    </Form.Item>*/}
        {/*)}*/}
      </Form>
    </Modal>
  );
};

export default ChangeStatusModal;
