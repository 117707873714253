import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Button, Typography, Row, Col, InputNumber } from 'antd';
import { useMutation } from '@apollo/client';
import { QUERY_LIST_BINS, MUTATION_CREATE_BULK_BIN } from './queries';
import routes from 'constants/routes';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

const { Title } = Typography;

const BinBulkCreate = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  const [createBulkBin] = useMutation(MUTATION_CREATE_BULK_BIN, {
    refetchQueries: [{ query: QUERY_LIST_BINS }],
  });

  const [stage, setStage] = useState('generate');

  const firstCorporateClientId = useMemo(() => {
    return (
      dbUser?.isCorporateAdmin &&
      dbUser?.userCorporateClients?.[0].corporate_client_id
    );
  }, [dbUser?.isCorporateAdmin, dbUser?.userCorporateClients]);

  const generateListBoxes = useCallback(
    (amount: number) => {
      const c: any[] = [];
      for (let i = 0; i < amount; i++) {
        c.push({
          location_id: null,
          ...(dbUser?.isCorporateAdmin
            ? { corporate_client_id: firstCorporateClientId }
            : {}),
        });
      }
      return c;
    },
    [dbUser?.isCorporateAdmin, firstCorporateClientId],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const bins = generateListBoxes(values.amount);

      setSaving(true);
      await createBulkBin({
        variables: {
          input: bins,
        },
      });
      setSaving(false);
      setStage('done');

      setTimeout(() => {
        navigate(routes.BINS);
      }, 1000);
    },
    [navigate, createBulkBin, generateListBoxes],
  );

  if (dbUser && !dbUser.userPermissions.ManageBins) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10} style={{ paddingLeft: 15 }}>
      <Form name="bins_bulk_add" layout="vertical" onFinish={handleSubmit}>
        <Col span={24}>
          <Title level={2}>Generate Return Bins</Title>
        </Col>

        {stage === 'generate' && (
          <>
            <Form.Item
              label="Amount of return bins to generate"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount of return bins to generate!',
                },
              ]}
            >
              <InputNumber
                max={1000}
                min={1}
                style={{ width: '100%' }}
                type="number"
                placeholder="amount of return bins"
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={saving}>
              Generate Return Bins
            </Button>
          </>
        )}

        {stage === 'done' && (
          <Col>
            <Title style={{ margin: '20px auto' }} level={3}>
              Done!
            </Title>
          </Col>
        )}
      </Form>
    </Row>
  );
};

export default BinBulkCreate;
