import React, { useContext, useMemo } from 'react';
import { Col, Layout } from 'antd';
import Sidebar from 'components/Sidebar/Sidebar';

import { Navigate, Route, Routes, useLocation } from 'react-router';
import ROUTES from 'constants/routes';
import { UserContext } from 'contexts/UserContext';

import './Index.sass';

import Users from './Users/Index';
import UserEdit from './Users/Edit';

import Cups from './Cups/Index';
import CupView from './Cups/View';
import CupBulkCreate from './Cups/BulkCreate';

import Assignments from './Assignments/Index';
import AssignmentEdit from './Assignments/Edit';

import CorporateClients from './CorporateClients/Index';
import CorporateClientEdit from './CorporateClients/Edit';

import Locations from './Locations/Index';
import LocationCreate from './Locations/Create';
import LocationRoleCreate from './Locations/CreateLocationRole';
import LocationEdit from './Locations/Edit';

import Boxes from './Boxes/Index';
import BoxView from './Boxes/View';
import BoxBulkCreate from './Boxes/BulkCreate';

import LoadView from './Boxes/Load/View';

import Bins from './Bins/Index';
import BinCreate from './Bins/BulkCreate';
import BinEdit from './Bins/Edit';

import Memberships from './Memberships/Index';
import MembershipCreate from './Memberships/Create';
import MembershipEdit from './Memberships/Edit';

import ToastMessageEdit from './ToastMessages/Edit';
import ToastMessageCreate from './ToastMessages/Create';
import ToastMessages from './ToastMessages/Index';

import Errors from './Errors/Index';

import ReportsDashboard from './Reports/Index';
import CorporateClientReportsDashboard from './Reports/ReportByCorporateClient/Index';
import { Home } from './Home/Home';
import { CorpClientHostedPage } from '../CorpClientHostedPage/CorpClientHostedPage';

const { Content } = Layout;

const Dashboard = () => {
  const { dbUser } = useContext(UserContext);
  const location = useLocation();
  const firstPath = useMemo(
    () =>
      dbUser?.userPermissions.AdminLogin
        ? ROUTES.HOME
        : dbUser?.userPermissions.ManageUsers
        ? ROUTES.USERS
        : dbUser?.userPermissions.ManageLocations ||
          dbUser?.userPermissions.ViewLocations
        ? ROUTES.LOCATIONS
        : dbUser?.userPermissions.ManageAssignments ||
          dbUser?.userPermissions.ViewAssignments
        ? ROUTES.ASSIGNMENTS
        : dbUser?.userPermissions.ManageCorporateClients
        ? ROUTES.CORPORATE_CLIENTS
        : dbUser?.userPermissions.ManageCups || dbUser?.userPermissions.ViewCups
        ? ROUTES.CONTAINERS
        : dbUser?.userPermissions.ManageDeliveryBoxes ||
          dbUser?.userPermissions.ViewDeliveryBoxes
        ? ROUTES.BOXES
        : dbUser?.userPermissions.ManageBins || dbUser?.userPermissions.ViewBins
        ? ROUTES.BINS
        : dbUser?.userPermissions.BasicReports ||
          dbUser?.userPermissions.AdminReports
        ? ROUTES.REPORTS_DASHBOARD
        : dbUser?.userPermissions.ManageSettings
        ? ROUTES.MEMBERSHIPS
        : ROUTES.FORBIDDEN,
    [dbUser],
  );

  const isPaddingOff =
    location.pathname.includes(ROUTES.TV_HOSTED_PAGE) ||
    location.pathname.includes(ROUTES.SUPER_ADMIN_TV) ||
    location.pathname.includes(ROUTES.HOME) ||
    location.pathname.includes(ROUTES.REPORTS_DASHBOARD);

  return (
    <Layout className="App">
      <Sidebar />
      <Layout
        className={`App-content__wrapper ${
          isPaddingOff ? 'no-padding' : 'padding-15px'
        }`}
      >
        <Content className="App-content">
          <Col>
            <Routes>
              <Route
                path={ROUTES.MAIN}
                element={<Navigate replace to={ROUTES.HOME} />}
              />
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.USERS + '/:user_id'} element={<UserEdit />} />
              <Route path={ROUTES.USERS} element={<Users />} />

              <Route
                path={ROUTES.CONTAINERS + '/create'}
                element={<CupBulkCreate />}
              />
              <Route
                path={ROUTES.CONTAINERS + '/:container_id'}
                element={<CupView />}
              />
              <Route path={ROUTES.CONTAINERS} element={<Cups />} />

              <Route
                path={ROUTES.ASSIGNMENTS + '/:assignment_id'}
                element={<AssignmentEdit />}
              />
              <Route path={ROUTES.ASSIGNMENTS} element={<Assignments />} />
              <Route
                path={ROUTES.SUPER_ADMIN_TV}
                element={<CorpClientHostedPage />}
              />
              <Route
                path={ROUTES.TV_HOSTED_PAGE + '/:ccUuid/internal'}
                element={<CorpClientHostedPage />}
              />
              <Route
                path={ROUTES.CORPORATE_CLIENTS + '/:corporate_client_id'}
                element={<CorporateClientEdit />}
              />
              <Route
                path={ROUTES.CORPORATE_CLIENTS}
                element={<CorporateClients />}
              />

              <Route
                path={ROUTES.BINS + '/:bin_id/edit'}
                element={<BinEdit />}
              />
              <Route path={ROUTES.BINS + '/create'} element={<BinCreate />} />
              <Route path={ROUTES.BINS} element={<Bins />} />

              <Route
                path={ROUTES.LOCATIONS + '/create'}
                element={<LocationCreate />}
              />
              <Route
                path={ROUTES.LOCATIONS + '/:location_id/edit'}
                element={<LocationEdit />}
              />
              <Route
                path={ROUTES.LOCATIONS + '/role/create'}
                element={<LocationRoleCreate />}
              />
              <Route path={ROUTES.LOCATIONS} element={<Locations />} />

              <Route
                path={ROUTES.BOXES + '/create'}
                element={<BoxBulkCreate />}
              />
              <Route path={ROUTES.BOXES + '/:box_id'} element={<BoxView />} />
              <Route path={ROUTES.BOXES} element={<Boxes />} />

              <Route path={ROUTES.LOADS + '/:load_id'} element={<LoadView />} />

              <Route
                path={ROUTES.MEMBERSHIPS + '/:level_id/edit'}
                element={<MembershipEdit />}
              />
              <Route
                path={ROUTES.MEMBERSHIPS + '/create'}
                element={<MembershipCreate />}
              />
              <Route path={ROUTES.MEMBERSHIPS} element={<Memberships />} />

              <Route
                path={ROUTES.TOAST_MESSAGES + '/:message_id/edit'}
                element={<ToastMessageEdit />}
              />
              <Route
                path={ROUTES.TOAST_MESSAGES + '/create'}
                element={<ToastMessageCreate />}
              />
              <Route path={ROUTES.TOAST_MESSAGES} element={<ToastMessages />} />
              <Route path={ROUTES.ERRORS} element={<Errors />} />

              <Route
                path={ROUTES.REPORTS_DASHBOARD}
                element={<ReportsDashboard />}
              />

              <Route
                path={ROUTES.REPORTS_DASHBOARD + '/:uuid'}
                element={<CorporateClientReportsDashboard />}
              />
              {/* default dashboard page */}
              {dbUser && (
                <Route
                  path={ROUTES.HOME}
                  element={<Navigate to={firstPath} />}
                />
              )}
            </Routes>
          </Col>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
