import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const ReportSvg = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33333 14C0.966667 14 0.652778 13.8694 0.391667 13.6083C0.130556 13.3472 0 13.0333 0 12.6667V3.33333C0 2.96667 0.130556 2.65278 0.391667 2.39167C0.652778 2.13056 0.966667 2 1.33333 2H4.13333C4.27778 1.6 4.51944 1.27778 4.85833 1.03333C5.19722 0.78889 5.57778 0.666668 6 0.666668C6.42222 0.666668 6.80278 0.78889 7.14167 1.03333C7.48056 1.27778 7.72222 1.6 7.86667 2H10.6667C11.0333 2 11.3472 2.13056 11.6083 2.39167C11.8694 2.65278 12 2.96667 12 3.33333V12.6667C12 13.0333 11.8694 13.3472 11.6083 13.6083C11.3472 13.8694 11.0333 14 10.6667 14H1.33333ZM1.33333 12.6667H10.6667V3.33333H1.33333V12.6667ZM2.66667 11.3333H7.33333V10H2.66667V11.3333ZM2.66667 8.66667H9.33333V7.33333H2.66667V8.66667ZM2.66667 6H9.33333V4.66667H2.66667V6ZM6 2.83333C6.14444 2.83333 6.26389 2.78611 6.35833 2.69167C6.45278 2.59722 6.5 2.47778 6.5 2.33333C6.5 2.18889 6.45278 2.06945 6.35833 1.975C6.26389 1.88056 6.14444 1.83333 6 1.83333C5.85556 1.83333 5.73611 1.88056 5.64167 1.975C5.54722 2.06945 5.5 2.18889 5.5 2.33333C5.5 2.47778 5.54722 2.59722 5.64167 2.69167C5.73611 2.78611 5.85556 2.83333 6 2.83333Z"
      fill="#605E55"
    />
  </svg>
);

const ReportIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ReportSvg} {...props} />
);

export default ReportIcon;
