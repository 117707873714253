import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const RecycleSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.125 6.625H1.11875C1.1875 6.625 1.24375 6.56875 1.24375 6.5V2.51094H9.50313V3.64531C9.50313 3.675 9.5125 3.70313 9.53125 3.72656C9.54178 3.74006 9.55487 3.75134 9.56978 3.75975C9.58468 3.76816 9.60111 3.77354 9.6181 3.77557C9.6351 3.7776 9.65232 3.77625 9.66879 3.7716C9.68526 3.76694 9.70065 3.75907 9.71406 3.74844L11.9531 1.99062C12.0203 1.9125 12.0094 1.82969 11.9531 1.78437L9.71406 0.028125C9.69102 0.00969403 9.66232 -0.000237833 9.63281 1.96633e-09C9.56094 1.96633e-09 9.50156 0.0593749 9.50156 0.13125V1.26562H1.12031C0.503125 1.26562 0 1.76875 0 2.3875V6.5C0 6.56875 0.05625 6.625 0.125 6.625ZM11.875 5.375H10.8813C10.8125 5.375 10.7563 5.43125 10.7563 5.5V9.48906H2.49687V8.35469C2.49687 8.325 2.4875 8.29687 2.46875 8.27344C2.45822 8.25994 2.44513 8.24866 2.43022 8.24025C2.41532 8.23184 2.39889 8.22646 2.3819 8.22443C2.3649 8.2224 2.34768 8.22375 2.33121 8.2284C2.31474 8.23306 2.29935 8.24093 2.28594 8.25156L0.046875 10.0094C-0.0203125 10.0875 -0.009375 10.1703 0.046875 10.2156L2.28594 11.9719C2.30937 11.9906 2.3375 12 2.36719 12C2.43906 12 2.49844 11.9406 2.49844 11.8687V10.7344H10.8828C11.5 10.7344 12.0031 10.2312 12.0031 9.6125V5.5C12 5.43125 11.9438 5.375 11.875 5.375Z"
      fill="#605E55"
    />
  </svg>
);

const RecycleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RecycleSvg} {...props} />
);

export default RecycleIcon;
