import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const NatureSvg = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33332 13.6667V12.3333H5.33332V9.66666H3.99999C3.07777 9.66666 2.29166 9.34166 1.64166 8.69166C0.991656 8.04166 0.666656 7.25555 0.666656 6.33333C0.666656 5.66666 0.84999 5.05277 1.21666 4.49166C1.58332 3.93055 2.07777 3.52222 2.69999 3.26666C2.79999 2.43333 3.16388 1.73611 3.79166 1.17499C4.41943 0.613884 5.15555 0.333328 5.99999 0.333328C6.84443 0.333328 7.58055 0.613884 8.20832 1.17499C8.8361 1.73611 9.19999 2.43333 9.29999 3.26666C9.92221 3.52222 10.4167 3.93055 10.7833 4.49166C11.15 5.05277 11.3333 5.66666 11.3333 6.33333C11.3333 7.25555 11.0083 8.04166 10.3583 8.69166C9.70832 9.34166 8.92221 9.66666 7.99999 9.66666H6.66666V12.3333H10.6667V13.6667H1.33332ZM3.99999 8.33333H7.99999C8.55555 8.33333 9.02777 8.13888 9.41666 7.74999C9.80555 7.36111 9.99999 6.88888 9.99999 6.33333C9.99999 5.93333 9.8861 5.56666 9.65832 5.23333C9.43055 4.89999 9.13332 4.65555 8.76666 4.49999L8.06666 4.19999L7.96666 3.43333C7.89999 2.93333 7.68055 2.51388 7.30832 2.17499C6.9361 1.83611 6.49999 1.66666 5.99999 1.66666C5.49999 1.66666 5.06388 1.83611 4.69166 2.17499C4.31943 2.51388 4.09999 2.93333 4.03332 3.43333L3.93332 4.19999L3.23332 4.49999C2.86666 4.65555 2.56943 4.89999 2.34166 5.23333C2.11388 5.56666 1.99999 5.93333 1.99999 6.33333C1.99999 6.88888 2.19443 7.36111 2.58332 7.74999C2.97221 8.13888 3.44443 8.33333 3.99999 8.33333Z"
      fill="#605E55"
    />
  </svg>
);

const NatureIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NatureSvg} {...props} />
);

export default NatureIcon;
