import React, { useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { AuthContext, AuthProvider } from './contexts/AuthContext';
import ApolloProvider from './services/ApolloProvider';
import { UserContext, UserProvider } from './contexts/UserContext';

import ROUTES from './constants/routes';

import Dashboard from './screens/Dashboard/Index';

import SignUp from './screens/Auth/SignUp/SignUp';
import SignIn from './screens/Auth/SignIn/SignIn';
import Forbidden from './screens/Auth/403';
import VerifyEmail from './screens/VerifyEmail/VerifyEmail';
import { SharebleHostedPage } from 'screens/ShareableHostedPage/SharebleHostedPage';
import Loader from './components/Loader/Loader';
import { CorpClientHostedPage } from './screens/CorpClientHostedPage/CorpClientHostedPage';

export const PRIMARY_COLOR = '#008C95';

function PrivateOutlet() {
  const location = useLocation();
  const { isSignedIn, loading } = useContext(AuthContext);
  const { dbUser } = useContext(UserContext);

  if (loading) {
    return <Loader />;
  }

  if (dbUser && !dbUser.permissions?.length) {
    return (
      <Navigate to={ROUTES.FORBIDDEN} state={{ from: location.pathname }} />
    );
  }

  if (!isSignedIn) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location.pathname }} />;
  }

  return <Dashboard />;
}

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorLink: PRIMARY_COLOR,
          colorSuccess: PRIMARY_COLOR,
          fontFamily: 'ProximaNova',
        },
      }}
    >
      <AuthProvider>
        <ApolloProvider>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
                <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
                <Route
                  path={ROUTES.SIGN_UP + '/:inviteId/:inviteEmail'}
                  element={<SignUp />}
                />
                <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />
                <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
                <Route
                  path={ROUTES.TV_HOSTED_PAGE + '/:ccUuid'}
                  element={<CorpClientHostedPage />}
                />
                <Route
                  path={ROUTES.TV_SHARED_PAGE}
                  element={<SharebleHostedPage />}
                />
                <Route
                  path={ROUTES.SUPER_ADMIN_TV_SHARED_PAGE}
                  element={<SharebleHostedPage />}
                />
                <Route path="*" element={<PrivateOutlet />} />
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </ApolloProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
